@import "ui-components/_variables.scss";

.block {
  color: var(--invertedText) !important;
  display: flex;
  align-items: center;
  height: calc(100% + 1px);

  svg {
    width: 24px;
    height: 24px;
  }

  &.active {
    text-decoration: underline;
  }
}

.iconWrapper {
  flex: 0 1 auto;
  height: 100%;
  display: flex;
  align-items: center;
}

.title {
  padding: 0 size("gutter");
}

.count {
  font-size: 1.1em;
  font-weight: bold;
}

.cartCount {
    width: 16px;
    height: 16px;
    background-color: var(--invertedText);
    color: var(--primaryAlt);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 8;
    top: -10px;
    right: 10px;  
}
