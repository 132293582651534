@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$priceInfoMinHeight: 40px;

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.block {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--light);
  border-radius: var(--borderRadius);
  overflow: hidden;
  text-decoration: none;
  box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 10%);

  transition: transform transition("duration--atoms") transition("ease-in"),
    box-shadow transition("duration--atoms") transition("ease-in");

  &:not(.dummy):hover {
    text-decoration: none;
    box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 30%);

    .name {
      text-decoration: underline;
    }
  }
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.imagePixel {
  display: block;
  flex: 0 0 auto;
  max-width: 100%;
  width: 100%;
}

.body {
  padding: size("gutter");
  text-align: left;
  flex: 1 0 auto;
  text-align: center;
}

.name {
  font-weight: 700;
  color: var(--text);
  margin-bottom: math.div(size("gutter--small"), 2);
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.brand,
.price {
  line-height: 1;
}

.brand {
  font-size: size("font--small");
  color: var(--textMuted);
  margin-top: math.div(size("gutter--mini"), 2);
  margin-bottom: math.div(size("gutter--mini"), 2);

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.price {
  font-size: size("font--mini");
  color: var(--text);
  margin-bottom: 0;
}

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.dummy {
  .image {
    position: relative;
    display: block;
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  span,
  p {
    display: block;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  .name {
    max-width: 50%;
  }

  .brand {
    max-width: 53%;
  }

  .price {
    max-width: 42%;
  }
}

.priceInfo {
  min-height: $priceInfoMinHeight;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: size("grid-gutter");

  .prevPrice {
    text-decoration: line-through;
  }

  .reducedPrice {
    color: color("negative");
    font-weight: 700;
  }
}
