@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  display: flex;
  align-items: center;
  font-weight: normal;
  color: var(--invertedText);

  .content {
    display: flex;
    flex-direction: row;
    font-size: size("font--mini");
  }

  .separator {
    margin: 0 math.div(size("gutter--small"), 2);
  }

  @media (max-width: breakpoint("medium")) {
    .content {
      display: none;
    }
  }
  @media (max-width: breakpoint("small")) {
    display: none;
  }
}
