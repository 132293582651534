@import "ui-components/_variables.scss";

.block {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.height {
  height: size("header-height");

  @media (min-width: breakpoint("small")) {
    .fullMenu:not(.onEarn) & {
      height: size("header-height") + size("sub-header-height");
    }
  }
}

@media (max-width: breakpoint("small")) {
  .hasMobileSubNav .height {
    height: size("header-height") + size("sub-header-height") !important;
  }
}

.onHomeView {
  .height {
    display: none;
  }

  .children {
    padding-top: 0;
  }
}

.notices {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: z-index("popup");
  box-shadow: 0 -6px 36px 0 rgba(0, 0, 0, 0.12);

  > div:not(:first-child) {
    border-top: 1px solid color("border--muted");
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.children {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  padding: size("gutter--large") 0 size("gutter--huge");

  @media (max-width: breakpoint("medium")) {
    padding-bottom: size("gutter--large");
  }
}

.bannerWarning {
  background-color: #fef7f8;
  text-align: center;
  margin-top: size("gutter--large") * -1;

  @media (max-width: 1240px) {
    margin-bottom: size("gutter--large");
  }

  .title[class] {
    margin: 0;
  }
}
