@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes shine {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 100%;
  }
}

.card {
  display: block;
  background: var(--light);
  border-radius: size("border-radius");
  overflow: hidden;
  text-decoration: none;
  box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 10%);
  width: 100%;

  &:hover {
    text-decoration: none;
  }
}

.content {
  display: flex;
  flex-direction: column;
  color: var(--text);
  padding: size("gutter--large");
  font-size: size("font--large");
  height: 100%;
  transition: color transition("duration--atoms") transition("ease-in");

  @media (max-width: (breakpoint("medium") - 1px)) {
    padding: size("gutter");
  }

  h2 {
    font-size: size("font--large");
    margin: 0;
  }

  .image {
    margin-top: size("gutter");
  }

  .card:hover & {
    color: var(--primary) !important;
  }

  .description {
    color: var(--textMuted);
    font-size: size("font--medium-small");
    line-height: font("line-height--medium-small");
    margin-top: size("gutter");
    padding: 0;

    video {
      width: 100%;
    }

    a {
      z-index: z-index("popup");
    }
  }

  .ctaBtn {
    padding: size("gutter--mini") size("gutter");
    text-transform: none;

    &Wrapper {
      display: flex;
      margin-top: auto;
      padding: size("gutter--small") 0 0;
    }
  }

  .readMore {
    font-size: size("font");
    font-weight: 500;
  }

  .meta {
    display: flex;
    flex-direction: column;
    position: static;
    margin-top: auto;
  }

  .tags {
    display: flex;
    gap: size("gutter--mini");
    margin-top: size("gutter");
  }

  .tag {
    display: inline-block;
    background-color: var(--primary);
    font-size: size("font--mini");
    color: var(--invertedText);
    border-radius: calc(var(--borderRadius) - 2px);
    padding: calc(size("grid-gutter") / 2) size("grid-gutter");
  }
}

.link {
  position: static;
  padding-top: size("gutter");

  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
  }
}

.shadow {
  opacity: 0;
  transition: opacity transition("duration--atoms") transition("ease-in");
  box-shadow: 0 12px 32px 0 rgba(0, 20, 31, 0.12);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .card:hover & {
    opacity: 1;
  }
}

.dummy {
  .card {
    width: 100%;
  }

  .content {
    display: block;
  }

  .title,
  .image,
  .description {
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  .title {
    height: size("font--large");
  }

  .description {
    height: size("font--large") * 5;
    margin-top: size("gutter");
  }
}
