@use "sass:math";

@import "ui-components/_variables.scss";

:export {
    mini: strip-unit(breakpoint("mini"));
    small: strip-unit(breakpoint("small"));
    medium: strip-unit(breakpoint("medium"));
    large: strip-unit(breakpoint("large"));
}

.block {
    background-color: var(--background);
}

.promotion {
    display: flex;
    background-color: var(--light);
    color: var(--text);
    box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 10%);

    .left,
    .right {
        width: 50%;
    }

    .left {
        margin-bottom: 0;
        margin-right: size("gutter--large");
    }

    .right {
        margin-left: size("gutter--large");
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        padding: size("gutter--large");
    }

    .description {
        margin-bottom: 0;
    }

    .ctaLarge {
        margin-top: size("gutter--large");
        font-size: size("font") !important;
    }

    @media (max-width: (breakpoint("medium") - 1px)) {
        flex-direction: column;

        .left,
        .right {
            width: 100%;
            margin: initial;
        }

        .left {
            &:not(.empty) {
                margin-bottom: size("gutter");
            }
        }
    }
}

.carousel {
    width: 100%;
    display: block;
    margin-bottom: size("gutter--small");
}

.howToEarnImage {
    height: 100%;
    object-fit: cover;
}

.currentPageInfoTop {
    display: flex;
    flex: 1 1 auto;
    & > div:first-child {
        width: math.div(2, 3) * 100%;
    }
    & > div:last-child {
        width: math.div(1, 3) * 100%;
    }
    & > div {
        min-width: 280px;
        flex-grow: 1;
    }
    > div:first-child:last-child {
        width: 100%;
    }
    @media (max-width: (breakpoint("small") - 1px)) {
        flex-wrap: wrap;
    }
}

.currentInfoDescription {
    font-size: size("font--small");
    max-width: 600px;
    a {
        display: table;
        background-color: var(--primary);
        color: var(--light);
        padding: size("gutter--mini") * 1.55 size("gutter") size("gutter--mini") * 1.6;
        margin-top: size("gutter") * 2;
    }
}

.newsListWrapper {
    display: flex;
    flex-direction: column;
    padding: size("gutter");
    margin: size("grid-gutter") 0;
    box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 10%);
    background-color: var(--light);
    color: var(--text);
    & > h2 {
        font-size: size("h2");
        margin-bottom: 0;
        color: var(--primary);
    }
    a {
        padding: 0;
        margin-top: size("gutter");
        border: none;
        word-break: break-word;
        h2 {
            font-size: size("h3");
            margin: size("grid-gutter") 0;
        }
        & > div:first-child {
            display: none;
        }
        * {
            text-align: left;
        }
        header span {
            font-size: size("font--small");
        }
        p {
            font-size: size("font");
        }
    }
}
.newsListLink:not(#foo) {
    background-color: var(--primary);
    color: var(--light);
    padding: size("gutter--mini") * 1.55 size("gutter") size("gutter--mini") * 1.6;
    font-size: size("font--small");
    line-height: font("line-height--paragraph");
    border-radius: 0;
    box-shadow: none;
    border: none;
    margin-top: auto;
    align-self: flex-start;
}

.currentInfo {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: breakpoint("medium")) {
        margin-left: -#{size("grid-gutter")};
        margin-right: -#{size("grid-gutter")};
    }
}

.currentInfoCardWrapper {
    padding: size("grid-gutter");
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    min-width: 280px;
    flex-grow: 1;
    @media (max-width: (breakpoint("medium") - 1px)) {
        width: 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}

.currentPageInfoBottom .currentInfoCardWrapper {
    padding: 0 size("grid-gutter");
}

.currentInfoCard {
    background-color: var(--light);
    color: var(--text);
    box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.1);
    flex-grow: 1;

    transition: transition("duration--atoms") transition("ease-in");
    > div:first-child:not(:last-child) {
        width: 100%;
    }
    &ImageWrapper {
        img {
            width: 100%;
            max-height: 450px;
        }
    }
    &Content {
        padding: size("gutter");
    }
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title,
    .description {
        max-width: 600px;
    }
}

.cta {
    padding: size("gutter--small") size("gutter--medium");

    &Large {
        font-size: size("font--large");
    }
}

.top {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @media (max-width: breakpoint("tiny")) {
        flex-direction: column;
        margin-bottom: size("gutter--large");
    }
}

.featuredProducts,
.earnOnline {
    display: flex;
    justify-content: center;
}

.title {
    font-size: size("h2");
    margin-bottom: size("gutter--small");
}

.description {
    font-size: size("font--small");
}

.ingress {
    margin-bottom: 0;
    font-size: size("font--small");
}

@media (max-width: (breakpoint("tiny") - 1px)) {
    .cta,
    .ctaLarge {
        padding-left: 0;
        font-size: size("font") !important;
    }
}

.wrapper {
    margin-top: size("gutter--huge");

    @media (max-width: (breakpoint("tiny") - 1px)) {
        margin-top: size("gutter--huge") 0;
    }

    & > *:not(:first-child) {
        margin-top: size("gutter--huge");
    }
}

.suppliers {
    padding: size("gutter");
    background-color: var(--light);
    margin: size("grid-gutter") size("grid-gutter") size("gutter--huge");
    box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 10%);
}

.currenInfoCardsSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: size("gutter");
  
    @media (min-width: breakpoint("medium")) {
        grid-template-columns: 1fr 1fr 1fr;
    }
  }

.currenInfoCardImage {
    display: flex;
    justify-content: center;
    margin: size("grid-gutter") 0;
}
  
.currenInfoCardsSectionCardWrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    margin: 0;

    @media (max-width: (breakpoint("medium") - 1px)) {
        flex: 0 0 100%;
    }
}

.currentInfo6Card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--light);
    color: var(--text);
    box-shadow: 5px 5px 15px 1px rgba(0,0,0,0.1);
    flex-grow: 1;
    transition: transition("duration--atoms") transition("ease-in");
    padding: size("gutter--large");
  
    @media (max-width: (breakpoint("small") - 1px)) {
      padding: size("gutter");
    }
  
    img {
      width: 100%;
      margin-bottom: size("grid-gutter");
    }
  
    h3 {
      margin-bottom: size("grid-gutter");
    }
  
    p {
      margin-top: 0;
    }
    &Content {
        padding: size("gutter");
    }
}

.productCarousel {
    margin: size("gutter--small") 0 0;

    h2 {
        font-size: size("h2");
        margin-bottom: size("gutter--small");
    }
}