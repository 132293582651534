@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  height: size("sub-header-height");
  background: #fff;
  color: var(--text);
  z-index: z-index("overlay");
  border-bottom: 1px solid var(--border);
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: size("gutter--large");

  > * + * {
    margin-left: size("gutter--small");
  }
}

.link {
  color: var(--text);

  &:hover {
    text-decoration: none;
  }
}

.item {
  font-size: size("font--small");
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;

  &.active .chevron {
    transform: rotate(90deg) scaleX(-1);
  }

  &:not(.item__cart) {
    color: var(--text) !important;
  }

  &:hover:not(.item__search):not(.item__customerBadge) {
    text-decoration: underline;
  }

  &.active:not(.item__search):not(.item__customerBadge) {
    text-decoration: underline;
  }

  &__search {
    svg {
      margin-right: size("gutter--mini");
      width: 22px;
      height: 22px;
    }

    & > div {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &:hover > div, &:active > div  {
      text-decoration: underline;
    }
  }

  &__customerBadge {
    &:hover {
      text-decoration: none;

      p:first-child {
        text-decoration: underline;
      }
    }

    &.active {
      p:first-child {
        text-decoration: underline;
      }
    }
  }

  & + & {
    margin-left: size("gutter--large");
  }

  svg + span {
    margin-left: size("gutter--mini");
  }

  &__categories {
    color: var(--text);

    .chevron {
      transition: all transition("duration--organisms") transition("ease-ease");
      top: 0px;
      transform: rotate(90deg);
      margin-left: size("gutter--mini");
    }
  }

  @media (min-width: breakpoint("small")) and (max-width: (breakpoint("medium") - 1px)) {
    font-size: 14px;
  }
}

.categories {
  background: #fff;
  border-radius: var(--borderRadius);
  margin-top: size("gutter--mini");
  text-transform: none;
  font-weight: normal;
}

.categories {
  position: absolute;
  padding: 0;
  overflow: hidden;
  top: size("sub-header-height");
  min-width: size("categories-width");
  margin-left: -#{math.div(size("categories-width"), 4)};
  right: auto !important;
}

.search {
  overflow-x: hidden;
  .item__search.active & {
    overflow-x: visible;
  }
  width: 0;
  transition: width transition("duration--organisms") transition("ease-out"),
              opacity transition("duration--organisms") transition("ease-out");
}

.categories {
  background: #fff;
  left: 0;
  right: 0;
  z-index: z-index("system") + 1;
  animation:  slideDown transition("duration--organisms") transition("ease-out") forwards;
}

.close {
  color: var(--primary);
  font-size: size("font--medium-small");
  margin: size("gutter") size("gutter--large") !important;

  &:hover {
    text-decoration: underline;
  }
}
