@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$bannerHeight: 450px;
$bannerHeight--small: 300px;
$buttonHeight: 3.8rem;

.block {
  .intro {
    max-width: 650px;
    margin-top: 0;

    p, strong {
      font-size: size("font--medium-small");
      line-height: font("line-height--medium-small");
    }

    .section {
      padding: size("gutter--large") 0;
    }
  }

  .section {
    margin-top: 0;
  }

  .heading {
    margin-bottom: size("gutter--small");
  }

  .activeFilters {
    @media (max-width: (breakpoint("tiny") - 1px)) {
      width: 100%;
    }
  }

  .activeFiltersRow {
    display: flex;
    align-items: center;
    
    &Header {
      margin-bottom: 0;
      margin-right: size("gutter");
  
      @media (max-width: (breakpoint("small") - 1px)) {
        margin-bottom: size("gutter--small");
      }
    }
  
    &Count {
      color: var(--primary);
    }
  
    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
    }
  
    @media (max-width: (breakpoint("small") - 1px)) {
      margin-bottom: size("gutter--small");
    }
  }

  .filterSection {
    display: flex;
    flex-direction: column;
    margin-bottom: size("gutter--large");

    @media (max-width: breakpoint("tiny")) {
      flex-direction: column;
    }

    @media (min-width: breakpoint("tiny")) {
      align-items: flex-start;
    }

    @media (min-width: breakpoint("small")) {
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
    }
  }

  .split {
    display: flex;

    img {
      max-height: 500px;
    }

    @media (min-width: breakpoint("small")) {
      @include stretch(math.div(size("gutter--large"), 2));

      & > * {
        flex: 1 0 50%;
        padding: 0 size("gutter--large");
      }
    }

    @media (max-width: (breakpoint("small") - 1px)) {
      flex-direction: column;

      & > *:first-child { order: 2; }
      & > *:last-child { order: 1; }
    }
  }

  .filterButton {
    height: $buttonHeight;
    flex: 0 0 auto;
    padding: size("gutter") !important;
    border-radius: size("border-radius");
    border: none;
    background: var(--light);
    transition: transition("duration--molecules") opacity transition("ease-in");

    &:hover:not(.active) {
      opacity: 0.7;
    }

    &.active {
      color: color("primary");
      border-color: color("primary");
      animation: pulse transition("duration--organisms") transition("ease-ease") forwards;
    }
  }

  .searchInput {
    flex: 1 1 auto;

    @media (min-width: breakpoint("mini")) {
      max-width: 260px;
    }
  }

  .filterIcon {
    fill: var(--text);
    user-select: none;
  }

  .cta {
    align-self: center;
    margin: size("gutter") 0 size("gutter");
  }

  .foldableDescription {
    white-space: pre-wrap;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > * {
      width: 100%;
      margin-top: size("gutter");
    }

    .affiliateSort {
      height: $buttonHeight !important;

      & > button {
        height: 100%;
      }
    }

    @media (min-width: breakpoint("tiny")) {
      flex-direction: row;
      justify-content: space-between;

      & > * {
        width: calc(50% - #{size("grid-gutter") * 0.5});
      }
    }

    @media (min-width: breakpoint("small")) {
      width: auto;

      & > * {
        width: 260px;
      }

      & > * + * {
        margin-left: size("grid-gutter");
      }
    }
  }

  .emptyList {
    display: flex;
    justify-content: center;
    margin-top: size("gutter--large");
  }
}

.cardsWrapper {
  display: grid;
  grid-template-columns:repeat(4, 1fr);
  grid-gap: size("gutter");
  margin: size("gutter--large") 0 size("gutter--large") * 2;

  @media (max-width: (breakpoint("medium") - 1px)) {
      grid-template-columns:repeat(2, 1fr);
  }

  @media (max-width: (breakpoint("mini") - 1px)) {
      grid-template-columns:repeat(1, 1fr);
  }
}
